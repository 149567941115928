//  libraries
import React from 'react'
import { useStaticQuery, graphql } from "gatsby";

//  styles
import { IntroDivSection } from './firstSection.style';

const FirstSection = () => {  
const data = useStaticQuery(graphql`
  {
    backgroundHeader: file(relativePath: {eq: "bg-contact-header.png"}) {
      publicURL
    }
    strapiContactUs {
      dataContact {
        firstSection {
          paragraph
          title
        }
      }
    }
  }
`);
const { paragraph, title } = data.strapiContactUs.dataContact.firstSection

  return (
    <IntroDivSection url={data.backgroundHeader.publicURL}>
      <h1>{title}</h1>
      <p>{paragraph}</p>
    </IntroDivSection>
  )
}

export default FirstSection;
