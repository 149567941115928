import styled from "styled-components";

export const FormDivSection = styled.div`
  background-color: #fff;
  background: linear-gradient(0deg, #B9C1CC -5%,rgba(255,255,255,1) 9%);
  padding: 11vh 5rem;
  padding-bottom: 20vh;
  flex-grow: grow;
  
  @media screen and (min-width: 100px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11vh 0;
  }

  @media screen and (min-width: 780px) {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    padding: 11vh 15rem;
  }

  @media screen and (min-width: 1025px) {
    column-gap: 10%;
  }

  & form {
    max-width: 651.6px;
    min-width: 36rem;
    @media screen and (min-width: 100px) {
      width: 90%;
      min-width: auto;
    }
    @media screen and (min-width: 1344px) {
      width: 50%;
      min-width: auto;
      margin-right: 10%;
    }
    animation: fadeIn 3s;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  & .contact-us-form-div {
    display: grid;
  }
  & input {
    width: 100%;
    border: 1px solid #d0d4da;
    height: 43.4px;
    border-radius: 22px;
    margin-bottom: 25px;
    padding: 0 22px;
    font-family: poppins;
    font-size: 16px;
    &:focus {
      outline: none;
      border: 1px solid #147efb;
    }
  }
  & span {
    color: #788293;
    opacity: 1;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 11.24px;
  }
  & select {
    width: 100%;
    border: 1px solid #d0d4da;
    height: 43.4px;
    border-radius: 22px;
    margin-bottom: 25px;
    padding: 0 22px;
    font-family: poppins;
    font-size: 16px;
    color: #373F49;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
    linear-gradient(45deg, transparent 50%, #d0d4da 50%),
    linear-gradient(135deg, #d0d4da 50%, transparent 50%),
    linear-gradient(to right, white, white);
    background-position:
      calc(100% - 20px) calc(1em + 4px),
      calc(100% - 15px) calc(1em + 4px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    &:focus {
      outline: none;
      border: 1px solid #147efb;
    }
  }
  & .checkbox {
    margin-left: 2.5rem;
    & label span {
      text-transform: none;
      margin: auto;
      margin-left: 15px;
      font-size: 16px;
      color: #788293;
      font-weight: 400;
      &:focus {
        outline: none;
        background-color: #EFF5FF;
      }
    }    
  }
  & button {
    width: 100%;
    height: 53px;
    margin: 57px 0;
    background-color: #147efb;
    color: #fff;
    border: none;
    border-radius: 29px;
    font-size: 16px;
    font-family: poppins;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      background-color: #147efb;
    }
    &:active {
      cursor: pointer;
      transform: translate(0, 5px);
    }
    &:focus {
      outline: none;
    }
  }  

  & .contact-us-form {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 80%;
    }

    @media screen and (min-width: 1024px) {
      width: 150%;
    }

    @media screen and (min-width: 1280px) {
      width: 200%;
    }
  }
`

export const MapDiv = styled.div`
  width: 50vmin;
  height: 50vmin;
  background: center no-repeat;
  background-image: url(${(props) => props.bgImage});
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: repeat(8, minmax(0, 1fr));
  @media screen and (min-width: 1440px) {
    width: 44vmin;
    height: 44vmin;
  }
  @media screen and (max-width: 600px) {
    width: 60vmin;
    height: 60vmin;
  }
  .locationMap1 {
    width: 100%;
    grid-column: 3 / 4;
    grid-row: 3;
  }
  .locationMap2 {
    width: 100%;
    opacity: 0.5;
    grid-column: 10 / 11;
    grid-row: 6;
    position: relative;
    top: 43%;
    right: 20%;
  }
  .mail1 {
    width: 116%;
    grid-column: 5 / 9;
    grid-row: 4;
    animation: moveInRight 0.8s ease-in;
  }
  .mail2 {
    width: 112%;
    opacity: 0.5;
    grid-column: 10 / 12;
    grid-row: 3;
    position: relative;
    left: 8%;
  }
  .mail3 {
    width: 123%;
    grid-column: 3 / 4;
    grid-row: 6;
    position: relative;
    left: 23%;
    top: 17%;
  }
`

export const TitleH2 = styled.h2`
  color: #373F49;
  font-size: 50px;
  margin-bottom: 2rem;
  text-align: center;   
  @media screen and (max-width: 1100px) {
    font-size: 40px;
  }
`
export const SubTitleH3 = styled.h2`
  color: #147efb;
  font-size: 25px;
  margin-bottom: 2rem;
  margin: 20px 0 40px 0;
  text-align: center;
  @media screen and (max-width: 1100px) {
    font-size: 15px;
  }
`

