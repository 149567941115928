import React, { useState, useEffect } from 'react'
import DateJsUtils from '@date-io/dayjs'
import Fade from 'react-reveal/Fade'
import { ContactUsAreasField } from '../../../components/Autocomplete/ContactUsAreas'
import {
  Input,
  PageContainer,
  FormContainer,
  FormSectionContainer,
  InputContainer,
  TwoColumnsInputContainer,
  Textarea,
  ContentContainer,
  ContactUsTitle,
  ErrorSpan
} from './form.style'
import Button from '../../../components/CustomButton'
import { useForm, useWatch } from 'react-hook-form'
import { Fetch } from '../../../utils/fetch'
import { saveSecureStorage } from '../../../utils/storage'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ToastContainer, toast } from 'react-toastify'
import { navigate } from 'gatsby'

export const ContactForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    clearErrors
  } = useForm()

  const area = useWatch({ control, name: 'area' })

  const [loading, setLoading] = useState(false)
  const [showMcNumberField, setShowMcNumberField] = useState(false)

  const onSubmit = async (data) => {
    const handleSubmit = Fetch('POST', '/contact-us-form', {
      body: { ...data },
      authorization: ''
    })

    toast.promise(handleSubmit, {
      pending: {
        render() {
          setLoading(true)
          return 'We are sending your request'
        }
      },
      success: {
        render() {
          reset()
          clearErrors()
          setLoading(false)
          navigate('/thank-you')
        }
      },
      error: {
        render() {
          setLoading(false)
          return 'Something went wrong. Please try again or contact support'
        }
      }
    })
  }

  useEffect(() => {
    setShowMcNumberField(
      ['Become a Partner', 'Carrier Services'].includes(area?.name)
    )
  }, [area])

  return (
    <MuiPickersUtilsProvider utils={DateJsUtils}>
      <PageContainer>
        <ContentContainer>
          <ContactUsTitle>
            <h1>ontact Us!</h1>
            <h2>What can we help you with?</h2>
          </ContactUsTitle>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <section className="form-inputs">
              <Fade right>
                <FormSectionContainer>
                  <InputContainer>
                    <p>
                      Let us know who we can direct your questions to.{' '}
                      <ErrorSpan>*</ErrorSpan>
                    </p>
                    <ContactUsAreasField
                      register={register}
                      required={true}
                      name="area"
                      control={control}
                      errors={errors}
                      rules={{ required: true }}
                    />
                    {errors.area && (
                      <ErrorSpan>This field is required</ErrorSpan>
                    )}
                  </InputContainer>
                  <TwoColumnsInputContainer>
                    <InputContainer>
                      <p>
                        First Name <ErrorSpan>*</ErrorSpan>
                      </p>
                      <Input {...register('first_name', { required: true })} />
                      {errors.first_name && (
                        <ErrorSpan>This field is required</ErrorSpan>
                      )}
                    </InputContainer>
                    <InputContainer>
                      <p>
                        Last Name <ErrorSpan>*</ErrorSpan>
                      </p>
                      <Input {...register('last_name', { required: true })} />
                      {errors.last_name && (
                        <ErrorSpan>This field is required</ErrorSpan>
                      )}
                    </InputContainer>
                  </TwoColumnsInputContainer>
                  <InputContainer>
                    <p>
                      Email <ErrorSpan>*</ErrorSpan>
                    </p>
                    <Input
                      {...register('email', { required: true })}
                      type="email"
                    />
                    {errors.email && (
                      <ErrorSpan>This field is required</ErrorSpan>
                    )}
                  </InputContainer>
                  <InputContainer>
                    <p>
                      Phone <ErrorSpan>*</ErrorSpan>
                    </p>
                    <Input {...register('phone', { required: true })} />
                    {errors.phone && (
                      <ErrorSpan>This field is required</ErrorSpan>
                    )}
                  </InputContainer>
                  <InputContainer>
                    <p>
                      Company <ErrorSpan>*</ErrorSpan>
                    </p>
                    <Input {...register('company', { required: true })} />
                    {errors.company && (
                      <ErrorSpan>This field is required</ErrorSpan>
                    )}
                  </InputContainer>
                  {showMcNumberField ? (
                    <InputContainer>
                      <p>
                        MC Number <ErrorSpan>*</ErrorSpan>
                      </p>
                      <Input
                        {...register('mc_number', {
                          required: showMcNumberField
                        })}
                      />
                      {showMcNumberField && errors.mc_number && (
                        <ErrorSpan>This field is required</ErrorSpan>
                      )}
                    </InputContainer>
                  ) : (
                    <div />
                  )}
                </FormSectionContainer>
                <FormSectionContainer>
                  <InputContainer>
                    <p>Message</p>
                    <Textarea
                      defaultValue=""
                      {...register('message', {})}
                      inputType={'textarea'}
                    />
                  </InputContainer>
                </FormSectionContainer>
              </Fade>
            </section>
            <section className="buttons-container">
              <Button
                className="submit-button"
                text="Submit"
                onClick={() => null}
                disabled={loading}
              />
            </section>
          </FormContainer>
        </ContentContainer>
      </PageContainer>
      <ToastContainer />
    </MuiPickersUtilsProvider>
  )
}
