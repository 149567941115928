//  libraries
import React from 'react'

//  components
import SEO from 'components/seo'
import Layout from 'components/layout'

//  containers
import FirstSection from 'containers/Contact/FirstSection'
import SecondSection from 'containers/Contact/SecondSection'
import FormSection from 'containers/Contact/Form'

//  styles
import { appTheme } from 'styles/theme'

export default () => {
  return (
    <Layout theme={appTheme}>
      <SEO title="Contact - Edge Logistics" />
      <FirstSection />
      <FormSection />
    </Layout>
  )
}
