import React, { useRef } from 'react'
import { useSearchableData } from '../../hooks/useSearchableData'
import { Controller } from 'react-hook-form'
import { Autocomplete } from '.'

export const ContactUsAreasField = ({
  name,
  control,
  rules,
  register,
  required
}) => {
  const options = useRef({
    time: 700,
    limit: 30,
    requestOptions: {
      externalUrl: process.env.GATSBY_COMMON_URL
    }
  })
  const {
    data = [
      { id: '1', name: 'Become a Partner' },
      { id: '2', name: 'Carrier Services' },
      { id: '3', name: 'Accounting' },
      { id: '4', name: 'Become a Shipper' },
      { id: '5', name: 'Marketing' },
      { id: '6', name: 'Other' }
    ],
    loading,
    setKeyword,
    getNextPage,
    noMore
  } = useSearchableData('/contact-us-areas', 'description', options.current)
  const handleSetKeyword = (_, value) => {
    setKeyword(value)
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          register={register}
          required={required}
          onChange={onChange}
          name={name}
          options={[
            { id: '1', name: 'Become a Partner' },
            { id: '2', name: 'Carrier Services' },
            { id: '3', name: 'Accounting' },
            { id: '4', name: 'Become a Shipper' },
            { id: '5', name: 'Marketing' },
            { id: '6', name: 'Other' }
          ]}
          loading={loading}
          asyncFilter={handleSetKeyword}
          getMore={getNextPage}
          error=""
          noMore={noMore}
          disabled={false}
          selectedValue={value}
          uniqueKeyName="id"
          optionKeyName="name"
          clear={() => null}
        />
      )}
    />
  )
}
