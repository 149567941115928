import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Fade } from 'react-reveal'
import { ContactForm } from './form'

import { FormDivSection, MapDiv } from './index.style'

const FormSection = () => {
  const data = useStaticQuery(graphql`
    {
      backgroundMap: file(relativePath: { eq: "circleMapBackground.svg" }) {
        publicURL
      }
      locationMap: file(relativePath: { eq: "location.svg" }) {
        publicURL
      }
      mail: file(relativePath: { eq: "mail.svg" }) {
        publicURL
      }
      strapiContactUs {
        dataContact {
          firstSection {
            paragraph
            title
          }
          formSection {
            fields {
              companyName
              email
              last_name
              name
              phoneNumber
              termsAndConditions
            }
            submitText
            title
            subTitle
            dropdownOptions
          }
        }
      }
    }
  `)
  const { locationMap, mail, backgroundMap } = data

  return (
    <FormDivSection>
      <div className="contact-us-form">
        <ContactForm />
      </div>
      <MapDiv className="mapDiv" bgImage={backgroundMap.publicURL}>
        <img
          className="locationMap1"
          src={locationMap.publicURL}
          alt="Location 1"
        />
        <img
          className="locationMap2"
          src={locationMap.publicURL}
          alt="Location 2"
        />
        <Fade left>
          <img className="mail1" src={mail.publicURL} alt="Mail 1" />
        </Fade>
        <img className="mail2" src={mail.publicURL} alt="Mail 2" />
        <img className="mail3" src={mail.publicURL} alt="Mail 3" />
      </MapDiv>
    </FormDivSection>
  )
}

export default FormSection
