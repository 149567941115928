import styled from "styled-components";
import { moveInLeft } from 'styles/animation'

export const IntroDivSection = styled.div`
  width: 100%;
  background-color: #F8FAFD;
  background: url(${(props) => props.url}) no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding-top: 0;
  height: 462px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 814px) {
    /* height: 90vh; */
    background-position-x: 22%;
  }
  h1 {
    font-size: 37px;
    margin-bottom: 4rem;
    ${moveInLeft('.8s', 'ease-out')}
    
    @media (min-width: 720px) and (max-width: 1038px) {
      font-size: 50px;
    }

    @media (min-width: 1038px) {
      font-size: 45px;
      line-height: 8rem;
    }
  }
  p {
    max-width: 80%;
    margin: 1rem;
    font-size: 20px;
    ${moveInLeft('.8s', 'ease-out')}

    @media (min-width: 720px) and (max-width: 1038px) {
      font-size: 25px;
    }

    @media (min-width: 1038px) {
      max-width: 932px;
      font-size: 22px;
    } 
  }
`

